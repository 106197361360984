import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3eb0237a = () => interopDefault(import('../pages/access-limit/index.vue' /* webpackChunkName: "pages/access-limit/index" */))
const _fc55aef4 = () => interopDefault(import('../pages/analysis-request/index.vue' /* webpackChunkName: "pages/analysis-request/index" */))
const _0c1a9ae6 = () => interopDefault(import('../pages/close/index.vue' /* webpackChunkName: "pages/close/index" */))
const _75c4b15c = () => interopDefault(import('../pages/create-shorturl/index.vue' /* webpackChunkName: "pages/create-shorturl/index" */))
const _6bdc2def = () => interopDefault(import('../pages/create-shorturl-List/index.vue' /* webpackChunkName: "pages/create-shorturl-List/index" */))
const _408190f9 = () => interopDefault(import('../pages/delete-plan/index.vue' /* webpackChunkName: "pages/delete-plan/index" */))
const _3bdb5356 = () => interopDefault(import('../pages/delete-setting/index.vue' /* webpackChunkName: "pages/delete-setting/index" */))
const _236c9a1e = () => interopDefault(import('../pages/env-setting/index.vue' /* webpackChunkName: "pages/env-setting/index" */))
const _63668055 = () => interopDefault(import('../pages/exam-end/index.vue' /* webpackChunkName: "pages/exam-end/index" */))
const _8a2bab06 = () => interopDefault(import('../pages/examinees/index.vue' /* webpackChunkName: "pages/examinees/index" */))
const _81069868 = () => interopDefault(import('../pages/examining/index.vue' /* webpackChunkName: "pages/examining/index" */))
const _21722996 = () => interopDefault(import('../pages/identification/index.vue' /* webpackChunkName: "pages/identification/index" */))
const _484524d6 = () => interopDefault(import('../pages/identification-ai/index.vue' /* webpackChunkName: "pages/identification-ai/index" */))
const _a9c20968 = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _444e6735 = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _556c5317 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _644f4752 = () => interopDefault(import('../pages/logins/index.vue' /* webpackChunkName: "pages/logins/index" */))
const _2d4a9252 = () => interopDefault(import('../pages/logview/index.vue' /* webpackChunkName: "pages/logview/index" */))
const _363ea7ab = () => interopDefault(import('../pages/management/index.vue' /* webpackChunkName: "pages/management/index" */))
const _f4c55d20 = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _2abc83b6 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _79ebdb44 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _cf05c096 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _24af547e = () => interopDefault(import('../pages/alerting/system-error.vue' /* webpackChunkName: "pages/alerting/system-error" */))
const _4ad5e207 = () => interopDefault(import('../pages/checkers/monitoring/index.vue' /* webpackChunkName: "pages/checkers/monitoring/index" */))
const _03ed5dd6 = () => interopDefault(import('../pages/alerting/_id.vue' /* webpackChunkName: "pages/alerting/_id" */))
const _479d538c = () => interopDefault(import('../pages/create-shorturl/_id.vue' /* webpackChunkName: "pages/create-shorturl/_id" */))
const _037fbee8 = () => interopDefault(import('../pages/examinees/_id/index.vue' /* webpackChunkName: "pages/examinees/_id/index" */))
const _65b13c02 = () => interopDefault(import('../pages/examinees/_id/login.vue' /* webpackChunkName: "pages/examinees/_id/login" */))
const _26bbaf06 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-limit",
    component: _3eb0237a,
    name: "access-limit"
  }, {
    path: "/analysis-request",
    component: _fc55aef4,
    name: "analysis-request"
  }, {
    path: "/close",
    component: _0c1a9ae6,
    name: "close"
  }, {
    path: "/create-shorturl",
    component: _75c4b15c,
    name: "create-shorturl"
  }, {
    path: "/create-shorturl-List",
    component: _6bdc2def,
    name: "create-shorturl-List"
  }, {
    path: "/delete-plan",
    component: _408190f9,
    name: "delete-plan"
  }, {
    path: "/delete-setting",
    component: _3bdb5356,
    name: "delete-setting"
  }, {
    path: "/env-setting",
    component: _236c9a1e,
    name: "env-setting"
  }, {
    path: "/exam-end",
    component: _63668055,
    name: "exam-end"
  }, {
    path: "/examinees",
    component: _8a2bab06,
    name: "examinees"
  }, {
    path: "/examining",
    component: _81069868,
    name: "examining"
  }, {
    path: "/identification",
    component: _21722996,
    name: "identification"
  }, {
    path: "/identification-ai",
    component: _484524d6,
    name: "identification-ai"
  }, {
    path: "/iframe",
    component: _a9c20968,
    name: "iframe"
  }, {
    path: "/inquiry",
    component: _444e6735,
    name: "inquiry"
  }, {
    path: "/login",
    component: _556c5317,
    name: "login"
  }, {
    path: "/logins",
    component: _644f4752,
    name: "logins"
  }, {
    path: "/logview",
    component: _2d4a9252,
    name: "logview"
  }, {
    path: "/management",
    component: _363ea7ab,
    name: "management"
  }, {
    path: "/monitoring",
    component: _f4c55d20,
    name: "monitoring"
  }, {
    path: "/privacy",
    component: _2abc83b6,
    name: "privacy"
  }, {
    path: "/redirect",
    component: _79ebdb44,
    name: "redirect"
  }, {
    path: "/terms",
    component: _cf05c096,
    name: "terms"
  }, {
    path: "/alerting/system-error",
    component: _24af547e,
    name: "alerting-system-error"
  }, {
    path: "/checkers/monitoring",
    component: _4ad5e207,
    name: "checkers-monitoring"
  }, {
    path: "/alerting/:id?",
    component: _03ed5dd6,
    name: "alerting-id"
  }, {
    path: "/create-shorturl/:id?",
    component: _479d538c,
    name: "create-shorturl-id"
  }, {
    path: "/examinees/:id",
    component: _037fbee8,
    name: "examinees-id"
  }, {
    path: "/examinees/:id/login",
    component: _65b13c02,
    name: "examinees-id-login"
  }, {
    path: "/",
    component: _26bbaf06,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
